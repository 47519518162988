// src/components/AuditListener.tsx

import React, { useEffect } from 'react';
import eventEmitter from '../helpers/eventEmitter';
import { registrarAuditoria } from '../services/auditoria';

const AuditListener = () => {
  useEffect(() => {
    const handleAuditoriaEvent = async (data: any) => {
      try {
        await registrarAuditoria(data);
        console.log('Auditoría registrada:', data);
      } catch (error) {
        console.error('Error al registrar auditoría:', error);
      }
    };

    eventEmitter.subscribe('registrarAuditoria', handleAuditoriaEvent);

    return () => {
      eventEmitter.unsubscribe('registrarAuditoria', handleAuditoriaEvent);
    };
  }, []);

  return <></>; // Este componente no renderiza nada
};

export default AuditListener;