// src/helpers/eventEmitter.js

const eventEmitter = {
    _events: {},
  
    dispatch: function (event, data) {
      if (!this._events[event]) return; // No hay suscriptores para este evento
      this._events[event].forEach(callback => callback(data));
    },
  
    subscribe: function (event, callback) {
      if (!this._events[event]) this._events[event] = [];
      this._events[event].push(callback);
    },
  
    unsubscribe: function (event, callback) {
        if (!this._events[event]) return;
        const index = this._events[event].indexOf(callback);
        if (index > -1) {
          this._events[event].splice(index, 1);
        }
    }
  };
  
  export default eventEmitter;