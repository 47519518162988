import * as React from 'react';
import {useEffect} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {Grid, Theme} from "@mui/material";
import {useAppSelector} from "../../redux/hooks";
import {store} from "../../redux/store";
import Notifications from "react-notification-system-redux";
import logo from "../../assets/img/logo.png"
import cafe from "../../assets/img/loginCafe.jpg"
import makeStyles from "@mui/styles/makeStyles";


const useStyles = makeStyles((theme:Theme) => ({
    root:{
        padding: theme.spacing(5),
        maxWidth: "1300px",
        margin: "0 auto"
    },
    logo:{
        width:336,
        display:"block",
        [theme.breakpoints.down('md')]: {
            margin: "0 auto",
        },
    },
    cafe:{
        width: 365,
        marginLeft: "100px",
        marginTop: "34px",
        display:"block",

        [theme.breakpoints.down('md')]: {
            width: 255,
            marginTop: 0,
            marginLeft: 0,
            margin: "0 auto !important",
        },
    }
}));
/**
 * Botón de inicio de sesión que utiliza la URL base desde la variable de entorno
 * Si REACT_APP_API_BASE_URL no está definida, usa el valor por defecto de desarrollo
 */
const LoginButton = () => <Button variant={"contained"} disableElevation color={"primary"} href={`${process.env.REACT_APP_API_BASE_URL || "http://localhost:1338/api"}/connect/microsoft`}>
    INICIAR CON MICROSOFT 365
</Button>





// const LogoutButton = (props) => <button onClick={props.onClick}>Logout</button>;

export default function SignInSide() {
    const classes = useStyles();

    const {state:urlState}:{state:any} = useLocation();
    const auth = useAppSelector(state => state.auth)
    const backurl = (urlState?.backurl) ? urlState.backurl:"/inicio";


    useEffect(()=>{
        return ()=> {
            store.dispatch(Notifications.removeAll())
        };
    },[])


    if ((auth.isAuthenticated && localStorage.getItem("_token"))) {
        return <Navigate replace to={backurl}/>;
    }


    return (
        <Box className={classes.root} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={cafe} alt="cafe" className={classes.cafe} />
                </Grid>
                <Grid item xs={12} md={6} sx={{
                    justifyContent:"center",
                    alignItems: "center",
                    display: "flex"
                }}>
                    <LoginButton/>
                </Grid>
            </Grid>
        </Box>
    )

}
