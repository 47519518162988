// src/context/NavigationContext.tsx
import React, { createContext, useState, useContext, ReactNode } from 'react';

// Separa la interfaz de los *datos* de navegación
interface NavigationData {
  viaAcceso?: 'Menú' | 'Tarjeta' | 'Destacado' | 'Favorito' | 'Notificacion';
  rutaMenu?: string| null ;
  menuId?: number | string | null;
  tarjetaId?: number | string | null;
  destacadoId?: number | string | null;
  indicadorId?: number | string | null;
  tipo?: 'Dashboard' | 'Tarjeta';
  detalle?: string | null;
}

// Define la interfaz completa del *contexto*, incluyendo los datos Y las funciones
interface NavigationContextProps {
  navigationData: NavigationData; // Los datos de navegación
  setNavigationContext: (data: Partial<NavigationData>) => void; // Función para actualizar
  clearNavigationContext: () => void; // Función para limpiar
}

// Crea el contexto.  El tipo es NavigationContextProps O undefined (para el valor inicial).
const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

// Provider
export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [navigationData, setNavigationData] = useState<NavigationData>({
    viaAcceso: undefined,
    rutaMenu: undefined,
    menuId: null,
    tarjetaId: null,
    destacadoId: null,
    indicadorId: null,
    tipo: undefined,
  });

  const setNavigationContext = (data: Partial<NavigationData>) => {
    setNavigationData(prevData => ({ ...prevData, ...data }));
  };

  const clearNavigationContext = () => {
    setNavigationData({
        viaAcceso: undefined,
        rutaMenu: undefined,
        menuId: null,
        tarjetaId: null,
        destacadoId: null,
        indicadorId: null,
        tipo: undefined,
    });
  };

  // El *valor* del contexto incluye tanto los datos como las funciones.
  const value: NavigationContextProps = {
    navigationData,
    setNavigationContext,
    clearNavigationContext,
  };

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

// Hook para usar el contexto (sin cambios)
export const useNavigation = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};