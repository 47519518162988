import React, {lazy, Suspense} from 'react';
import {BrowserRouterProps, Route, Router, Routes} from "react-router-dom";
import type {BrowserHistory} from "history";

import './App.scss';
import {useMediaQuery, useTheme} from "@mui/material";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {esES} from '@mui/material/locale';
import {getColor} from "./assets/colors";

import Login from "./views/cuenta/login";


import LoadingProgress from "./components/LoadingProgress";
import {useAppSelector} from "./redux/hooks";
import historyR from "./helpers/history";
import LoginRedirect from "./views/cuenta/LoginRedirect";
import AuditListener from './components/AuditListener';
import { NavigationProvider } from './context/NavigationContext';

const Layouts = lazy(() => import('./layouts/index'));


function App() {
  const auth = useAppSelector(state => state.auth)

  const usuUIcolor = "#000E77";
  const smVewport = useMediaQuery(useTheme().breakpoints.down('sm'));

  let theme = createTheme({
    typography: {
      fontFamily: [
        'Helvetica Neue',
        'Arial',
        'sans-serif',
        'Montserrat',
        'Roboto',
      ].join(','),
    },
    components: {
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiDialog:{
        styleOverrides:{
          paper: {
           // borderRadius: 15,
          }
        }
      },
      MuiListItemIcon:{
        styleOverrides:{
          root: {
            minWidth:"26px !important"
          }
        }
      },
      ...(!smVewport? {
        MuiInputLabel:{
          styleOverrides:{
            root: {
              "&:not([class*='-outlined'])":{
                lineHeight: 1,
                position: "initial !important",
                transform: "none !important",
                color: "inherit !important",
                marginBottom: "5px !important",
                fontSize: "13px !important",
                fontWeight: "normal",
              },
              "&.Mui-error":{
                color:"#d32f2f !important",
                "&+div":{
                  borderColor: "#d32f2f !important",
                }
              }

            },
          }
        },
        MuiInputBase:{
          styleOverrides:{
            root:{
              background: "white",
              "&:not([class*='MuiInputBase-multiline'])":{
                height: 41,
              },
              "& .Mui-disabled":{
                background: "#f1f1f1",
                borderRadius: 5,
              },
              "&:not([class*='MuiOutlinedInput'])":{
                borderRadius:5,
                border: "1px solid #D8D8D8",
                marginTop: "0px !important",
                color:"#707070",
                fontSize:"14px",
                "& [class*='-input']:not([class*='Pagination'])":{ //:not(select)
                  padding: "13px 15px",
                  height: 13,
                  minHeight: 13,
                },
                "& .MuiSvgIcon-root.MuiSelect-icon":{
                  background: "#6b747c !important",
                  borderRadius: "0px 4px 4px 0px",
                  top: "0px",
                  color: "white !important",
                  padding: "8px 4px",
                  "&.MuiSelect-iconOpen":{
                    borderRadius: "4px 0px 0px 4px",
                  }
                },
                "&:before, &:after":{
                  display:"none !important",
                }
              }
            },
            adornedStart:{
              paddingLeft: 10,
              "& input": {
                paddingLeft: "0px !important",
              }
            },
            adornedEnd:{
              paddingRight: 10,
              "& .MuiAutocomplete-endAdornment": {
                right: 2,
                top: "calc(50% - 20px)",
              },
              '& [class*="popupIndicator"], & .MuiIconButton-edgeEnd':{
                background: "#6b747c !important",
                borderRadius: "0px 4px 4px 0px",
                height: "41px",
                width: "32px",
                color: "white !important",
                marginLeft: "4px"
              },
              '& [class*="popupIndicatorOpen"]':{
                borderRadius: "4px 0px 0px 4px",
              }
            },
            // disabled:{
            //   background: "#d32f2f !important",
            // }
          }
        }
      }:{}),
      MuiButton: {
        styleOverrides:{
          root: {
           // borderRadius: "0"
          },
          outlinedSizeSmall:{
            // boxShadow: "0px 1px 3px black",
            fontSize: "12px",
            fontWeight: "bold",
            height: "28px"
          }
        }
      },
      MuiTable:{
        styleOverrides:{
          root:{
            borderLeft: "1px solid #C4C4C4",
            borderTop: "1px solid #C4C4C4",
          }
        }
      },
      MuiTableRow: {
        styleOverrides:{
          head: {
            height: 8,
          },
          root: {
            //height: 44,
          },
          footer:{
            cursor: "auto !important",
          }
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root:{
            fontSize:"12px",
            color: "black !important",
            borderRight: "1px solid #C4C4C4",
            borderBottom: "1px solid #C4C4C4",
            "&:not([class*='sizeSmall']):not([class*='paddingCheckbox'])":{
              height: 40,
              padding: ".5rem",
              boxSizing: "border-box",
            },
          },
          head: {
            background:"#ddddde",
            borderBottom: "1px solid #C4C4C4",
            fontWeight: "bold",
          },
          body: {
          },
          footer:{
            background:"#ddddde",
            fontSize: "0.85rem",
            fontWeight:"bold"
          }
        }
      },
      MuiPaper:{
        styleOverrides:{
          root:{
            overflow:"inherit !important"
          }
        }
      }
    },
    palette: {
      // mode: 'dark',
      ...getColor(usuUIcolor),
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    mixins: {
      toolbar: {
        minHeight: 50,
      }
    },
  }, esES);

  return (
    <NavigationProvider>
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider  theme={theme}>
            <Suspense fallback={<LoadingProgress/>}>
              <BrowserRouter /* basename={"/banfondesa2"} */>
              <AuditListener />
                <Routes>
               
                  {auth.isAuthenticated? (
                      <Route path={"*"} element={<Layouts />}/>
                  ):(
                      <Route path="/" element={auth.status === "loading"? <LoadingProgress/>:<Login/>}/>
                  )}

                  <Route path="/login" element={<Login/>}/>
                  <Route path="/connect/:providerName/redirect" element={<LoginRedirect/>}/>
                  <Route path={"*"} element={<Login />}/>

                </Routes>
              </BrowserRouter>
            </Suspense>
          </ThemeProvider >
        </StyledEngineProvider>
      </div>
      </NavigationProvider>
  );
}

export default App;


export function BrowserRouter({basename, children,}: BrowserRouterProps) {

  let historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = historyR;
  }

  let history = historyRef.current;
  let [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
      <Router
          basename={basename}
          children={children}
          location={state.location}
          navigationType={state.action}
          navigator={history}
      />
  );
}
