// src/services/auditoria.js
import axios from 'axios';
import { base_url} from './index'; 
import {getIdToken} from './auth';

const API_URL = base_url;
const AUTH_TOKEN = getIdToken();

/**
 * Registra un nuevo evento de auditoría.
 * @param {object} data - Datos del evento de auditoría.
 */
export const registrarAuditoria = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/auditorias`, {data:data}, {
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error al registrar auditoría: ${data}`, error);
    throw error;
  }
};

/**
 * Obtiene los registros de auditoría.
 * @param {object} filters - Filtros para la consulta (opcional).
 */
export const obtenerAuditorias = async (filters = {}) => {
  try {
    const response = await axios.get(`${API_URL}/auditorias`, {
      headers: {
        Authorization: `Bearer ${AUTH_TOKEN}`,
      },
      params: {
        ...filters,
        populate: '*'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener auditorías:', error);
    throw error;
  }
};